@media only screen and (max-width: 1280px) {
   .main-container-left {
      margin-left: 120px;
  }
}
@media only screen and (max-width: 780px) {
   .item-container{
      padding: 0px;
   }
   .main-section{
      display: block;
      padding: 20px;
   }
   .main-container-left{
      margin-left: 0px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
   .main-container-left img.app-image{
      width: 300px;
      justify-content: center;
      margin: 20px 0px;
  }
  .main-container-right{
     margin-left: 0px;
  }
  .footer{
     display: none;
  }
  .right-container{
     min-height: 100vh;
  }
 }
@media only screen and (max-width: 1024px) {
   .main-container-left{
      margin-left: 50px;
  }
 }
 