:root {
    --theme-color: #43beb2;
    --danger-color: #ff6e6e;
    --white: #ffffff;
  }

body {
    margin: 0px; 
    padding: 0px
}
.ui.grid.main{
    min-height: 100vh;
    margin: 0px !important;
}
.row {
    margin: 0px !important; 
    padding: 0px !important;
}
.content-container{
    width: 100%;
}
.item-container{
    padding: 20px;
    flex: 1;
    min-height: 100%;
}
.container-fluid {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
}
.ui.grid>.column:not(.row), .ui.grid>.row>.column.silid-container {
    margin: 0px !important;
    padding: 0px !important;
}
.silid-container {
    margin: 0px;
    padding: 0px;
    background-color: rgb(67 190 178);
}
.ui.grid>.column:not(.row), .ui.grid>.row>.column.light-container {
    margin: 0px !important;
    padding: 0px !important;
}
/* .light-container {
    height: 100vh;
    background-color: #efefef;
} */
.main-container-left{
    margin-left: 180px;
    margin-top: 25px;
}
img.play-store-image{
    width: 150px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 999;    
    -webkit-box-shadow: 0px 2px 6px rgba(0,0,0 /20%);
    -moz-box-shadow: 0px 2px 6px rgba(0,0,0 /20%);
    box-shadow: 0px 2px 6px rgba(0,0,0 /20%);
}
/* img.app-image{
width: 800px;
} */
.main-container-right{
    margin-left: 150px;
    display: flex;
    min-height: calc(100vh - 115px);
}
.main-section{
    min-height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    
}
.logo{
    width: 140px !important;
    margin-top: -10px;
}
.footer {
    /* display: flex; */
    position: absolute;
    bottom: 0;
    width: 100%;
    /* text-align: center; */
    /* padding-right: 1rem; */
    text-align: center;
    margin-left: -20px;
    color: #ffffff;
}
.footer a{
    color: #FFF;
    font-size: 14px;
}
.footer span{
    margin: 0 10px;
}
/* .footer p{
    color: #43beb2;
    font-weight: bold;
    margin: 0 auto;
}
.footer p a{
    color: #43beb2;
    font-weight: bold;
}
.footer p span{
    margin: 0 10px;
} */
.mobile-footer {
    /* display: flex; */
    width: 100%;
    /* text-align: center; */
    padding-right: 1rem;
    background-color: var(--theme-color);
    text-align: center;
    color: #ffffff;
}
.mobile-footer a{
    color: #FFF;
    /* padding: 10px; */
}
.mobile-footer span{
    margin: 0 10px;
}
.footer-link {
    margin: 0 auto;
}
.app-footer {
    /* display: flex; */
    position: absolute;
    bottom: 0;
    width: 100%;
    /* text-align: center; */
    padding-right: 1rem;
}
.app-footer a{
    color: rgb(67 190 178);
}
.contact-box{
    /* height: 335px; */
    padding: 15px 15px 55px 15px;
    -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
}
input{
    border: 0px !important;
    border-bottom: 1px solid rgb(67 190 178) !important;
    border-radius: 0px !important;
}
textarea{
    border: 0px !important;
    border-bottom: 1px solid rgb(67 190 178) !important;
    border-radius: 0px !important;
}
button.ui.button.btn-custom{
    background-color: rgb(67 190 178);
    color: #FFF;
    border-radius: 0px;
}
a.btn-custom{
    background-color: rgb(67 190 178);
    color: #FFF;
    border-radius: 0px;
    height: 30px;
    padding: 5px 10px;
}
button.ui.button.btn-danger{
    background-color: rgb(255 132 132);
    color: #FFF;
    border-radius: 0px;
}
button.ui.button.btn-logout{
    background-color: #878888;
    color: #FFF;
    border-radius: 0px;
}
.ui.pointing.text.app-menu.menu{
    margin: 0px;
}
.ui.pointing.text.app-menu.menu a{
    color: #000;
    height: 25px;
    margin-right: 15px;
}
.ui.pointing.text.app-menu.menu a.active{
    color: rgb(67 190 178);
    font-weight: bold;
    border-bottom: 1px solid rgb(67 190 178);
}


/************** Admin Styles Start ************/
.left-container .item-container .profile-box{
    min-height: 160px;
    background-color: rgb(67 190 178);
    padding: 9px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
}
.profile-box h4{
    margin: 10px 0px;
    color: #FFF;
}
.profile-box span{
    margin: 10px 0px;
    color: #FFF;
}
.profile-box small{
    margin: 10px 0px;
    color: #FFF;
}
.mobile-sidebar .profile-box{
    background-color: var(--theme-color);
    padding: 10px;
}
.mobile-sidebar .profile-box .c-icon{
    position: absolute;
    top: -20px;
    right: -35px;
}
img.company-profile{
    margin: 0 auto;
}
.app-container-right{
    margin: 20px 0px ;
}
.app-footer a.footer-link{
    color: rgb(67 190 178);
}
.ui.secondary.vertical.pointing.menu .active.item{
    border-color: var(--theme-color);
    color: var(--theme-color);
}
.ui.pointing.secondary.vertical.app-sidebar.menu{
    min-height: calc(100vh - 255px);
}
img.company-profile{
    height: 70px;
}
.ui.dimmer{
    background-color: rgb(0, 0, 0, 0) !important
}
.ui.celled.very.basic.table.data-table td{
    padding: 6px;
}
.table-button-section{
    width: 100%;
}
.btn-right{
    float: right;
}
.table-filter-secton{
    width: 100%;
    justify-content: space-between;
    display: flex;
}
.table-show-size{
    padding: 3px;
    width: 60px;
    border: 0;
    border-bottom: 1px solid var(--theme-color);
}
.table-show-size::selection{
    border: 1px solid var(--theme-color);
}
select.table-show-size::selection{
    border: 1px solid #1e9c90;
    background-color: #cce2ff;
    color: #1e9c90;
}
.table-footer{
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
}
.pagination{
    float: right;
}
.ui.secondary.pointing.menu .active.item{
    border-color: var(--theme-color);
    color: var(--theme-color);
}
.ui.secondary.pointing.menu.item:focus{
    border-color: transparent;
    border: none;
}
.ui.secondary.pointing.menu.item:hover{
    border-color: var(--theme-color);
    color: var(--theme-color);
}
.form-action-section{
    float: right;
    margin-bottom: 20px;
}
button.btn-icon-round{
    padding: 4px !important;
    margin-right: 5px !important;
    background-color: transparent !important;
    color: var(--theme-color) !important;
}
button.btn-icon-round:hover{
    background-color: var(--theme-color) !important;
    color: #ffffff !important;
}
.ui.secondary.vertical.pointing.menu .item a{
    color: black;
}
.ui.secondary.vertical.pointing.menu .item a.active{
    color: var(--theme-color);
    font-weight: bold;
}
.table-subheader{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.table-subheader .ui.positive.message{
    padding: 5px;
    margin: 5px;
}
.custom-select .ui.selection.dropdown{
    border: 0px;
    border-radius: 0px !important;
    border-bottom: 1px solid var(--theme-color);
}
.c-box{
    width: 100%;
}
.c-box button{
    position: absolute;
    top: 24px;
    right: 36px;
    background-color: rgba(255,255,255,.7) !important;
    color: var(--theme-color) !important;
    z-index: 999;
}
.c-box button:hover{
    background-color: rgba(255,255,255,1) !important;
}
.p-box{
    border-top: 1px solid #FFF;
    padding: 5px 0px;
    margin-top: 5px;
}
.admin-profile .image img{
    width: 80px !important;
    height: 80px !important;
    border-radius: 50% !important;
}
/* .c-box .c-icon i{
    color: var(--theme-color)
} */
.form-box{
    margin: 20px 0px;
}
.form-modal .header{
    padding: 10px !important;
    font-size: 17px !important;
}
.form-modal .ui.grid{
    margin-bottom: 20px;
}
.form-modal .actions{
    float: right; 
    margin-bottom: 15px
}
.confirm-modal .actions{
    padding: 10px !important;
    background-color: #ffffff !important;
    border-top: 0px !important;
}
.confirm-modal .header{
    padding: 10px !important;
    font-size: 17px !important;
}
.ui.form .field.error .input, .ui.form .field.error label, .ui.form .fields.error .field .input, .ui.form .fields.error .field label{
    color: var(--danger-color) !important
}
.ui.form .field .prompt.label{
    color: var(--danger-color) !important;
    border: 1px solid #ffd3d3 !important;
}
.confirm-popper button.ui.primary.button{
    background-color: var(--theme-color);
    border-radius: 0px;
}
.confirm-popper button.ui.button{
    background-color: rgb(255 132 132);
    border-radius: 0px;
    color: #ffffff;
}
.confirm-popper button.ui.button::before{
    font-family: "Font Awesome 5 Free";
    content: "\f057";
}
.ui.button>.icon:not(.button){
    opacity: 1;
}
.ui.form .field.disabled>label, .ui.form .fields.disabled>label{
    opacity: 1 !important;
}
.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled{
    opacity: 1 !important;
}
/* .Toastify__toast--warning{
    background-color: #000;
} */
/* label.profile-image-lable{
    background-image: url('./Images/d-profile.jpg');
    width: 120px;
    height: 120px;
} */
.leaflet-container {
    width: 100%;
    height: calc(100vh - 200px);
  }
  .ui.modal>.close {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 1px;
    z-index: 999;
    opacity: .8;
    font-size: 1.25em;
    color: var(--theme-color);
    width: 2.25rem;
    height: 2.25rem;
    padding: 6px;
    border: 2px solid var(--theme-color);
    border-radius: 50%;
}
.ui.thin.left.sidebar.mobile-sidebar{
    z-index: 9999;
    position: fixed;
    top: 47px;
    height: calc(100vh - 48px);
    width: 245px;
}
 .ui.inverted.menu{
    background: #FFF;
 }
 .ui.inverted.menu .item, .ui.inverted.menu .item>a:not(.ui){
    color: #000;
    font-size: 16px;
 }
.ui.inverted.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu .item .active{
    color: var(--theme-color);
    font-weight: bold;
 }
 .ui.mobile-menu.menu{
     width: 100%;
     border: none;
     border-radius: 0 !important;
     margin-bottom: 0px;
     height: 48px;
     padding: 0px;
     align-items: center;
    }
.ui.mobile-sidebar.menu .item{
    text-align: left;
}
.ui.mobile-menu.menu .item:before{
    background: transparent !important
}
img.mobile-logo{
    width: 90px;
    height: 24px;
    margin-block: auto;
}
.ui.mobile-menu.menu a.item.menu-text{
    font-weight: bold;
    color: var(--theme-color);
}
.menu.transition.visible.dropdown-right{
    z-index: 9998;
}
.subheader-searchbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
}
.subheader-searchbar .ui.input.filter-item{
    min-width: calc(100% / 4.5);
}

.subheader-searchbar .ui.dropdown.filter-item{
    min-width: calc(100% / 4.5);
    border: 0px;
    border-bottom: 1px solid #43beb2;
    border-radius: 0px;
    padding: 7px;
}
.subheader-searchbar .ui.dropdown.filter-item .divider.default.text{
    width: 88%;
}
.subheader-searchbar .ui.dropdown.filter-item .divider.text{
    width: 88%;
}
.main-section .scrollable-container{
    height: 100vh; 
    overflow-y: scroll;
    padding-right: 20px;
    text-align: justify;
}
